import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  LeavingReasonSet,
  LeavingReasonType,
  Product,
  RegistrationStatus,
  SHORT_FORMATTED_DATE_FORMAT_FNS,
  useGetUserQuery,
  User,
} from '@schooly/api';
import { DateSelect, PropertyTypeSelect } from '@schooly/components/filters';
import { FormTextField } from '@schooly/components/form-text-field';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { DeleteIcon, InformationIcon, LockIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { formatPhoneNumberWithCode } from '@schooly/utils/phone-number';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { ContactButton } from '../../../pages/PayableFees/PayableFeesGrid';
import { RegistrationStatusLeavingReasonSelect } from './RegistrationStatusLeavingReason';
import { RegistrationStatusWarning } from './RegistrationStatusWarning';
import {
  AddRegistrationStatus,
  DisabledRegistrationStatus,
  RegistrationForm,
} from './StudentRegistrationForm';
import { useRegistrationMissingStatuses } from './useRegistrationMissingStatuses';

export interface RegistrationStatusComponent {
  validateYear: ({ start, end }: { start: string; end: string }) => void;
  validateDateOrder: (statuses: AddRegistrationStatus[]) => void;
}
export interface RegistrationStatusProps {
  containerRef?: React.RefObject<HTMLElement>;
  schoolId: string;
  leavingStatusId: string;
  disabledStatuses: DisabledRegistrationStatus[];
  initial?: boolean;
  products: Product[];
  leavingStatus?: RegistrationStatus;
}

export const RegistrationStatusComponent: FC<RegistrationStatusProps> = ({
  containerRef,
  schoolId,
  disabledStatuses,
  initial,
  products,
  leavingStatusId,
  leavingStatus,
}) => {
  const { $t } = useIntl();
  const { control, watch, formState } = useFormContext<RegistrationForm>();

  const {
    fields: statusFields,
    append: appendStatus,
    remove: removeStatus,
    insert: insertStatus,
  } = useFieldArray({
    control,
    name: 'statuses',
  });

  const statuses = watch('statuses');
  const selectedStatuses = statuses?.filter((status) => status.school_property_id);

  const {
    hasLastUnsuccessfulStatus,
    obligatoryProductStatuses,
    originalStatuses,
    removeStatusWarning,
    findMissingStatuses,
  } = useRegistrationMissingStatuses({
    schoolId,
    selectedStatuses,
    products,
  });

  const addField = useCallback(() => {
    appendStatus({
      formId: uuidv4(),
      school_property_id: '',
      applies_from: '',
    });

    if (containerRef) {
      requestAnimationFrame(() => {
        const container = containerRef.current;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        }
      });
    }
  }, [appendStatus, containerRef]);

  const insertField = useCallback(
    (index: number, statusId: string) => {
      return () => {
        insertStatus(index, {
          formId: uuidv4(),
          school_property_id: statusId,
          applies_from: '',
        });

        if (containerRef) {
          requestAnimationFrame(() => {
            const container = containerRef.current;
            if (container) {
              container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth',
              });
            }
          });
        }
      };
    },
    [containerRef, insertStatus],
  );

  useEffect(() => {
    if (!statusFields.length) {
      addField();
    }
  }, [statusFields.length, addField]);

  useEffect(
    () =>
      findMissingStatuses({
        ageGroupPropertyId: formState?.defaultValues?.age_group_property_id || '',
        halfDay: !!formState?.defaultValues?.half_day,
        schoolYearId: formState?.defaultValues?.school_year_id || '',
      }),
    // we need to trigger this function once on component mount (it doesn't get triggered on initial render by itself)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const { unsubscribe } = watch((value, type) => {
      if (!type.name) {
        return;
      }

      findMissingStatuses({
        ageGroupPropertyId: value.age_group_property_id || '',
        halfDay: !!value.half_day,
        schoolYearId: value.school_year_id || '',
      });
    });

    return () => unsubscribe();
  }, [watch, findMissingStatuses, statusFields]);

  const statusWarningsDisabled = !initial || !selectedStatuses?.length || hasLastUnsuccessfulStatus;

  return (
    <Stack gap={2}>
      {statuses?.map((statusField, index) => {
        const statusError = formState.errors['statuses']?.[index]?.school_property_id;
        const dateError = formState.errors['statuses']?.[index]?.applies_from;
        const disabledStatus = disabledStatuses.find((s) => s.formId === statusField.formId);
        const required = index === 0 || disabledStatus?.required;
        const requiredLabel = required ? 'required' : 'optional';

        const currentStatusIndex = originalStatuses.findIndex(
          (status) => status.id === statusField.school_property_id,
        );
        const nextStatusIndex = originalStatuses.findIndex(
          (status) => status.id === statuses[index + 1]?.school_property_id,
        );

        const isLeavingStatus =
          !!leavingStatusId && statusField.school_property_id === leavingStatusId;
        const isLeavingStatusDisabled =
          isLeavingStatus && Number(statusField.created_by?.role) === SchoolUserRole.Parent;

        const renderStatusIcon = () => {
          if (isLeavingStatusDisabled) {
            return !!leavingStatus && <DisabledLeavingStatusTooltip status={leavingStatus} />;
          }

          if (statusFields.length > 1 || disabledStatus?.lockIcon) {
            return (
              <IconButton
                inverse
                onClick={() => !disabledStatus?.lockIcon && removeStatus(index)}
                sx={(theme) => ({
                  alignSelf: 'flex-start',
                  mt: 1.25,
                  color: theme.palette.common.grey,
                  '&:hover': { color: theme.palette.text.primary },
                })}
              >
                {disabledStatus?.lockIcon ? disabledStatus?.lockIcon : <DeleteIcon />}
              </IconButton>
            );
          }
        };

        return (
          <>
            {!statusWarningsDisabled &&
              !!selectedStatuses.length &&
              !hasLastUnsuccessfulStatus &&
              obligatoryProductStatuses.map((status) => {
                const statusExists = statuses.find((s) => s.school_property_id === status.statusId);

                if (
                  !statusExists &&
                  !status.shown &&
                  status.statusIndex < currentStatusIndex &&
                  index === 0
                ) {
                  return (
                    <RegistrationStatusWarning
                      key={status.statusId}
                      status={status}
                      onClick={insertField(0, status.statusId)}
                      onRemove={() => removeStatusWarning(status.statusId)}
                    />
                  );
                }

                return null;
              })}

            <Stack gap={0.5}>
              <Stack
                key={statusField.formId}
                direction="row"
                gap={1.25}
                sx={{
                  '& .MuiSelect-select': {
                    maxHeight: 44,
                  },
                }}
              >
                <Stack
                  flex={1}
                  position="relative"
                  sx={{
                    '& .MuiFormControl-root': {
                      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        backgroundColor: 'transparent',
                      },
                    },
                  }}
                >
                  <Controller
                    name={`statuses.${index}.school_property_id`}
                    control={control}
                    rules={{
                      required,
                    }}
                    render={({ field }) => {
                      const hasError = Boolean(statusError);
                      const errorMessage = getControllerErrorText(statusError, undefined, $t);
                      const disabled =
                        disabledStatus?.disabledFields.includes('school_property_id');

                      return (
                        <PropertyTypeSelect
                          adornmentLabel={requiredLabel}
                          userRole={SchoolUserRole.Student}
                          label={$t({ id: 'schoolProperty-Status' })}
                          propertyType={SchoolPropertyType.Status}
                          schoolId={schoolId}
                          errorMessage={errorMessage}
                          hasError={hasError}
                          fullWidth
                          {...field}
                          disabled={disabled || isLeavingStatusDisabled}
                          optionalLabel={disabledStatus?.tag}
                          renderEndIcon={
                            disabledStatus
                              ? () => disabledStatus.endAdornment
                              : isLeavingStatusDisabled
                              ? () => <></>
                              : undefined
                          }
                        />
                      );
                    }}
                  />
                </Stack>

                <Stack flex={1}>
                  <Controller
                    name={`statuses.${index}.applies_from`}
                    control={control}
                    rules={{
                      required,
                    }}
                    render={({ field }) => {
                      const errorMessage = dateError
                        ? getControllerErrorText(dateError, undefined, $t)
                        : '';

                      const disabled = disabledStatus?.disabledFields.includes('applies_from');

                      return (
                        <DateSelect
                          onSetDate={(date) => {
                            field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                          }}
                          date={field.value}
                          opened={(!field.value && !!disabledStatus) || undefined}
                          hasValues={Boolean(field.value)}
                          placeholder={$t({ id: 'schoolProperty-Status-AppliesForm' })}
                          requiredLabel={requiredLabel}
                          disabled={disabled || isLeavingStatusDisabled}
                          renderRightIcon={
                            disabled || isLeavingStatusDisabled ? () => <></> : undefined
                          }
                          error={
                            errorMessage
                              ? {
                                  message: errorMessage,
                                  type: 'validate',
                                }
                              : undefined
                          }
                        />
                      );
                    }}
                  />
                </Stack>

                {renderStatusIcon()}
              </Stack>

              {isLeavingStatus && (
                <Stack flexDirection="row" gap={1.25}>
                  <Stack flex={1}>
                    <Controller
                      name={`statuses.${index}.leaving_reason`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field, fieldState }) => {
                        return (
                          <RegistrationStatusLeavingReasonSelect
                            disabled={isLeavingStatusDisabled}
                            schoolId={schoolId}
                            selectedReason={field.value as LeavingReasonSet | undefined}
                            onSelect={field.onChange}
                            error={fieldState.error}
                            label={$t({ id: 'profile-registrationReason' })}
                            placeholder={$t({ id: 'profile-registrationReason' })}
                            renderRightIcon={isLeavingStatusDisabled ? () => <></> : undefined}
                            {...field}
                          />
                        );
                      }}
                    />
                  </Stack>

                  {statusField.leaving_reason?.type === LeavingReasonType.Other && (
                    <Stack flex={1}>
                      <Controller
                        name={`statuses.${index}.leaving_reason.title`}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState }) => {
                          return (
                            <FormTextField
                              fullWidth
                              helperText={getControllerErrorText(fieldState.error, undefined, $t)}
                              error={!!fieldState.error}
                              disabled={isLeavingStatusDisabled}
                              {...field}
                            />
                          );
                        }}
                      />
                    </Stack>
                  )}

                  <IconButton sx={{ visibility: 'hidden' }}>
                    <LockIcon />
                  </IconButton>
                </Stack>
              )}
            </Stack>

            {!statusWarningsDisabled &&
              !!selectedStatuses.length &&
              !hasLastUnsuccessfulStatus &&
              obligatoryProductStatuses.map((status) => {
                const statusExists = statuses.find((s) => s.school_property_id === status.statusId);

                if (
                  !statusExists &&
                  !status.shown &&
                  status.statusIndex > currentStatusIndex &&
                  status.statusIndex < nextStatusIndex
                ) {
                  const prevStatusIndex = statuses.findIndex((selectedStatus) => {
                    const prevStatusId = originalStatuses.find(
                      (el, index) => index === status.statusIndex - 1,
                    )?.id;
                    return selectedStatus.school_property_id === prevStatusId;
                  });

                  return (
                    <RegistrationStatusWarning
                      key={status.statusId}
                      status={status}
                      onClick={insertField(prevStatusIndex + 1, status.statusId)}
                      onRemove={() => removeStatusWarning(status.statusId)}
                    />
                  );
                }

                return null;
              })}
          </>
        );
      })}

      <SimpleButton
        sx={{
          alignSelf: 'flex-start',
          '&.Mui-disabled': {
            backgroundColor: (theme) => theme.palette.background.default,
          },
        }}
        onClick={addField}
        startIcon={<PlusIcon />}
      >
        <FormattedMessage id="schoolProperty-AddStatus" />
      </SimpleButton>
    </Stack>
  );
};

const DisabledLeavingStatusTooltip: FC<{ status: RegistrationStatus }> = ({ status }) => {
  const { formatMessage } = useIntl();

  const { data: user } = useGetUserQuery(
    { userId: status.created_by.user_id },
    { enabled: !!status?.created_by.user_id },
  );

  return (
    <Tooltip
      title={
        <Stack gap={1.25}>
          <Typography>{formatMessage({ id: 'profile-LeavingStatusDisabled' })}</Typography>

          <Stack gap={1}>
            <Stack direction="row">
              <Typography
                variant="body1"
                sx={(theme) => ({
                  color: `${theme.palette.common.grey} !important`,
                  mr: 0.5,
                })}
              >
                {formatMessage({ id: 'profile-SubmissionDate' })}
                {': '}
              </Typography>

              <Typography variant="body1" color="common.grey2">
                <Box display="inline-block">
                  {status.created_at &&
                    format(new Date(status.created_at), SHORT_FORMATTED_DATE_FORMAT_FNS)}
                </Box>
              </Typography>
            </Stack>

            <Stack direction="row">
              <Typography
                variant="body1"
                sx={(theme) => ({
                  color: `${theme.palette.common.grey} !important`,
                  mr: 0.5,
                })}
              >
                {formatMessage({ id: 'profile-SubmittedBy' })}
                {': '}
              </Typography>

              <Typography variant="body1" color="common.grey2">
                <Box
                  display="inline-block"
                  sx={{ ':hover': { textDecoration: 'underline' }, cursor: 'pointer' }}
                >
                  {getUserFullName(status.created_by)}
                </Box>
              </Typography>
            </Stack>
          </Stack>

          <Stack gap={0.5}>{!!user && <UserAdditionalInfo user={user} />}</Stack>
        </Stack>
      }
    >
      <IconButton
        inverse
        sx={(theme) => ({
          alignSelf: 'flex-start',
          mt: 1.25,
          color: theme.palette.common.grey,
          '&:hover': { color: theme.palette.text.primary },
        })}
      >
        <InformationIcon />
      </IconButton>
    </Tooltip>
  );
};

const UserAdditionalInfo: FC<{ user: User }> = ({ user }) => {
  const { email, telephone } = user;
  const { formatMessage } = useIntl();

  if (!email && !telephone) return null;

  return (
    <Stack gap={0.5} mt={1} flex={1}>
      {email && (
        <ContactButton
          title={email}
          copyMessage={formatMessage({ id: 'clipboard-EmailCopied' })}
          copyContent={email}
        />
      )}
      {telephone && (
        <ContactButton
          title={formatPhoneNumberWithCode(telephone)}
          copyMessage={formatMessage({ id: 'clipboard-PhoneCopied' })}
          copyContent={telephone}
        />
      )}
    </Stack>
  );
};
