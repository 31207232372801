import { Icon, Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import { CheckIcon, Spin } from '@schooly/style';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { DELAY_BEFORE_CHANGE_STATUS } from '../SchoolNotificationsAttendance/SchoolNotificationsAttendanceContent';

interface SchoolNotificationsEmailProps {
  loading: boolean;
  onSubmit: (value: string) => Promise<unknown>;
}

interface SchoolNotificationsEmailForm {
  email: string;
}

export const SchoolNotificationsEmail: FC<SchoolNotificationsEmailProps> = ({
  loading,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();

  const form = useForm<SchoolNotificationsEmailForm>({
    defaultValues: {
      email: '',
    },
  });

  const email = form.watch('email');

  const [showCompleted, setShowCompleted] = useState<boolean>();

  const onChangesCompleted = useCallback(() => {
    setShowCompleted(true);

    setTimeout(() => {
      setShowCompleted(false);
    }, DELAY_BEFORE_CHANGE_STATUS);
  }, [setShowCompleted]);

  const handleEmailSave = useCallback(async () => {
    await onSubmit(email);

    onChangesCompleted();
  }, [email, onSubmit, onChangesCompleted]);

  const checkEmailsDebounced = useMemo(
    () =>
      debounce(async () => {
        const isValid = await form.trigger();

        if (!isValid) {
          return;
        }

        if (!loading) {
          handleEmailSave();
        }
      }, 500),
    [form, loading, handleEmailSave],
  );

  useEffect(() => {
    const sub = form.watch((value, { name }) => {
      if (!name) return;
      if (name === 'email') {
        checkEmailsDebounced();
      }
    });

    return () => sub.unsubscribe();
  }, [form, checkEmailsDebounced]);

  const selectedIcon = useMemo(() => {
    if (loading) {
      return <Spin />;
    }

    return showCompleted ? <CheckIcon /> : null;
  }, [loading, showCompleted]);

  return (
    <FormProvider {...form}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        maxWidth={500}
        gap={2}
      >
        <ControlTextField
          name="email"
          control={form.control}
          fullWidth
          hideLabel
          rules={{
            required: true,
            pattern: {
              value: VALID_EMAIL_REGEXP,
              message: formatMessage({
                id: 'input-ErrorInvalidEmail',
              }),
            },
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            '.MuiOutlinedInput-root': { border: 'none' },
            '.MuiOutlinedInput-input': { height: '12px !important' },
            maxWidth: 500,
            width: '100%',
          }}
        />

        <Icon>{selectedIcon}</Icon>
      </Stack>
    </FormProvider>
  );
};
