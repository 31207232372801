import { Box, Button, Icon, IconButton, Tooltip, Typography } from '@mui/material';
import {
  MAX_PAGE_SIZE,
  Product,
  RegistrationStatusUpdate,
  SyncUser,
  useAddEnrollmentMutation,
  useDeleteEnrollmentMutation,
  useGetEnrollments,
  useGetProductsListQuery,
  useGetSchoolLeavingStatus,
  useGetSchoolReEnrollmentStatus,
  useUpdateEnrollmentMutation,
} from '@schooly/api';
import { isReEnrollmentPendingRegistration } from '@schooly/components/annual-roll-over';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { MODAL, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { CrossIcon, DeleteIcon, Loading, LockIcon, ModalHeader, Spin } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullNameWithTitle } from '@schooly/utils/get-user-full-name-with-title';
import { propsAreNotEmpty } from '@schooly/utils/predicates';
import { isAfter, isBefore, startOfToday } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';

import AccessDenied from '../../../../components/common/AccessDenied';
import {
  getNextSchoolYear,
  isOpenedRegistration,
} from '../../../../components/common/ProfileModal/utils';
import {
  DisabledRegistrationStatus,
  RegistrationForm,
  StudentRegistrationForm,
} from '../../../../components/common/StudentRegistration/StudentRegistrationForm';
import { useInvoicingRegistrationRestrictions } from '../../../../components/common/StudentRegistration/useInvoicingRegistrationRestrictions';
import { useReEnrollmentActions } from '../../../../components/common/StudentRegistration/useReEnrollmentActions';
import { useReEnrollmentStatuses } from '../../../../components/common/StudentRegistration/useReEnrollmentStatuses';
import {
  convertFormToRegistrationData,
  convertRegistrationDataToForm,
  instanceOfCanceledEnrollment,
  isCurrentStatusSelected,
} from '../../../../components/common/StudentRegistration/utils';
import useQueryStringParams from '../../../../hooks/useQueryStringParams';
import useSchoolYears from '../../../../hooks/useSchoolYears';

interface StudentRegistrationContentProps {
  schoolId: string;
  user: SyncUser;
}

export const StudentRegistrationContent: FC<StudentRegistrationContentProps> = ({
  schoolId,
  user,
}) => {
  const { id } = useQueryStringParams();
  const invalidateQueries = useInvalidateListQueriesFor('student');
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { permissions } = useAuth();
  const { showError } = useNotifications();
  const { showNotification } = useNotifications();
  const studentId = user?.relation_id || '';
  const pathname = `/students/${studentId}`;

  const { schoolYears } = useSchoolYears();

  const handleClose = useCallback(() => {
    navigate({
      pathname,
      hash: '#registrations',
    });
  }, [navigate, pathname]);

  const {
    data,
    isFetching: isRegistrationFetching,
    refetch,
  } = useGetEnrollments(
    {
      schoolId: schoolId ?? '',
      studentId,
    },
    { enabled: !!schoolId && !!studentId, refetchOnMount: 'always' },
  );

  const { data: reEnrollmentData, isLoading: isReEnrollmentDataLoading } =
    useGetSchoolReEnrollmentStatus(schoolId ?? '', {
      enabled: !!schoolId,
    });

  const { data: leavingStatus } = useGetSchoolLeavingStatus(schoolId, {
    enabled: !!schoolId,
    refetchOnMount: 'always',
  });

  const { handleCancelEnrollment, isReEnrollmentUpdating, handleEnrollmentChange } =
    useReEnrollmentActions({
      fetchEnrollments: refetch,
      reEnrollmentData,
    });

  const currenRegistration = useMemo(() => {
    return data?.enrollments?.find((e) => e.id === id);
  }, [data?.enrollments, id]);

  const hasPermission = useMemo(() => permissions.includes('registration_manager'), [permissions]);

  const addEnrollment = useAddEnrollmentMutation();
  const updateEnrollment = useUpdateEnrollmentMutation();
  const deleteEnrollment = useDeleteEnrollmentMutation();
  const { state } = useLocation();

  const initialState = state?.initialState;

  const canceledEnrollmentParams = instanceOfCanceledEnrollment(initialState)
    ? initialState
    : undefined;

  const { activePropertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const { getConfirmation } = useConfirmationDialog();

  const { isActiveRegistration, nextSchoolYear, reEnrollment } = useMemo(() => {
    const isActiveRegistration = currenRegistration
      ? isOpenedRegistration(currenRegistration.statuses)
      : null;
    const nextSchoolYear = currenRegistration
      ? getNextSchoolYear(currenRegistration.school_year.id, schoolYears)
      : null;

    const reEnrollment = data?.enrollments.find(
      (e) => e.school_year.id === nextSchoolYear?.id && e.type === 're_enrollment',
    );

    return { isActiveRegistration, nextSchoolYear, reEnrollment };
  }, [currenRegistration, data?.enrollments, schoolYears]);

  const handleRemoveRegistration = useCallback(async () => {
    if (!id || currenRegistration?.initial) return;
    const isAffectsReEnrollment = isActiveRegistration && reEnrollment;

    const isConfirmed = await getConfirmation({
      textId: isAffectsReEnrollment
        ? 'enrollment-RemoveRegistrationWithReEnrollmentRemove'
        : 'enrollment-DeleteRegistrationInfo',
      textValues: nextSchoolYear ? { yearName: nextSchoolYear.name } : undefined,
    });

    if (!isConfirmed) return;

    if (isAffectsReEnrollment) {
      await deleteEnrollment.mutateAsync(reEnrollment.id);
    }

    deleteEnrollment.mutate(id, {
      onSuccess: () => {
        refetch();
        invalidateQueries();
        handleClose();
        showNotification({
          type: 'success',
          textId: 'confirmation-RegistrationDelete',
        });
      },
      onError: showError,
    });
  }, [
    currenRegistration?.initial,
    deleteEnrollment,
    getConfirmation,
    handleClose,
    id,
    invalidateQueries,
    isActiveRegistration,
    nextSchoolYear,
    reEnrollment,
    refetch,
    showError,
    showNotification,
  ]);

  const handleSubmit = useCallback<SubmitHandler<RegistrationForm>>(
    async (formData) => {
      if (!schoolId || !formData.school_year_id) return;

      const updatedEnrollment = convertFormToRegistrationData(formData);
      const currentStatusSelected = updatedEnrollment.statuses.some(
        isCurrentStatusSelected(activePropertiesMap['status']),
      );

      const hasSameYearReEnrollment = Boolean(
        data?.enrollments.some(
          (e) =>
            e.id !== id &&
            e.school_year.id === updatedEnrollment.school_year_id &&
            isReEnrollmentPendingRegistration(e),
        ),
      );

      if (hasSameYearReEnrollment && currentStatusSelected) {
        return showError({
          reason: formatMessage({ id: 'enrollment-CreateRegistrationWithSameYearReEnrollment' }),
        });
      }

      if (!id) {
        addEnrollment.mutate(
          {
            schoolId,
            studentId,
            enrollment: updatedEnrollment,
          },
          {
            onSuccess: () => {
              refetch();
              invalidateQueries();
              handleClose();
              showNotification({
                type: 'success',
                textId: 'confirmation-RegistrationCreate',
              });
            },
            onError: showError,
          },
        );
        return;
      } else {
        if (!currenRegistration) return;

        if (canceledEnrollmentParams) {
          const { rejectedReEnrollment } = canceledEnrollmentParams;

          handleCancelEnrollment({
            canceledEnrollment: {
              id,
              ...updatedEnrollment,
              statuses: formData.statuses
                .filter(propsAreNotEmpty)
                .reduce<RegistrationStatusUpdate[]>(
                  (acc, { formId, ...props }) =>
                    props.applies_from && props.school_property_id
                      ? [
                          ...acc,
                          formId === canceledEnrollmentParams.id
                            ? { ...props, read_only: true }
                            : props,
                        ]
                      : acc,
                  [],
                ),
            },
            rejectedReEnrollment,
            onSuccess: () => {
              refetch();
              invalidateQueries();
              handleClose();

              showNotification({
                type: 'success',
                textId: 'confirmation-RegistrationUpdate',
              });
            },
          });
          return;
        }
        const pendingReEnrollment = reEnrollment && !reEnrollment.enrollment_status;

        if (isActiveRegistration && pendingReEnrollment) {
          const isConfirmed = await handleEnrollmentChange({
            registration: currenRegistration,
            updatedRegistration: updatedEnrollment,
            statuses: activePropertiesMap['status'],
            sameAgeGroup: formData.same_age_group,
            ageGroupId: formData.age_group_property_id,
            reEnrollment,
          });

          if (!isConfirmed) return;
        }

        updateEnrollment.mutate(
          {
            enrollmentId: id,
            enrollment: updatedEnrollment,
          },
          {
            onSuccess: () => {
              refetch();
              invalidateQueries();
              handleClose();

              showNotification({
                type: 'success',
                textId: 'confirmation-RegistrationUpdate',
              });
            },
            onError: showError,
          },
        );
      }
    },
    [
      formatMessage,
      activePropertiesMap,
      addEnrollment,
      canceledEnrollmentParams,
      currenRegistration,
      data?.enrollments,
      handleCancelEnrollment,
      handleClose,
      handleEnrollmentChange,
      id,
      invalidateQueries,
      isActiveRegistration,
      reEnrollment,
      refetch,
      schoolId,
      showError,
      showNotification,
      studentId,
      updateEnrollment,
    ],
  );

  const fetchRegistrations = useCallback(async () => {
    const { data } = await refetch();
    return data?.enrollments ?? [];
  }, [refetch]);

  const registrationFormData = useMemo(
    () =>
      currenRegistration
        ? convertRegistrationDataToForm(currenRegistration, canceledEnrollmentParams)
        : undefined,
    [canceledEnrollmentParams, currenRegistration],
  );

  const { isFutureYearRegistration, isPastYearRegistration } = useMemo(() => {
    const yearId = currenRegistration?.school_year.id;
    const year = schoolYears.find((y) => y.id === yearId);
    if (!year) return { isFutureYearRegistration: false, isPastYearRegistration: false };

    const today = startOfToday();

    const isPastYearRegistration = isBefore(newDateTimezoneOffset(year.end), today);
    const isFutureYearRegistration = isAfter(newDateTimezoneOffset(year.start), today);

    return { isFutureYearRegistration, isPastYearRegistration };
  }, [currenRegistration?.school_year.id, schoolYears]);

  const { data: productsData, isFetching: isFetchingProducts } = useGetProductsListQuery(
    { schoolId, pageSize: MAX_PAGE_SIZE },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const products = useMemo(
    () =>
      productsData?.pages.reduce<Product[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [productsData?.pages],
  );

  const {
    isFetching: isFetchingInvoicingData,
    disabledStatuses: triggerDisabledStatuses,
    canEditAgeGroup,
    checkCanEditStatus,
  } = useInvoicingRegistrationRestrictions({
    yearId: currenRegistration?.school_year.id ?? '',
    schoolId,
    studentId,
    statuses: currenRegistration?.statuses ?? [],
    products,
  });

  const reEnrollmentDisabledStatuses = useReEnrollmentStatuses({
    statuses: activePropertiesMap['status'],
    canceledEnrollmentParams,
    enrollmentFormStatuses: registrationFormData?.statuses ?? [],
    enrollment: currenRegistration,
  });

  const disabledStatusesMap = [...reEnrollmentDisabledStatuses, ...triggerDisabledStatuses].reduce<
    Record<string, DisabledRegistrationStatus>
  >((acc, obj) => {
    if (!acc[obj.formId]) {
      acc[obj.formId] = obj;
    }
    return acc;
  }, {});

  const disabledStatuses = Object.values(disabledStatusesMap);

  const registrationCurrentStatuses =
    currenRegistration?.statuses.filter((status) => status.school_property.category?.current) ?? [];
  const registrationHasCurrentStatus = !!registrationCurrentStatuses.length;
  const registrationCurrentTriggerStatuses = registrationCurrentStatuses.filter(
    (status) => !checkCanEditStatus(status.school_property.id),
  );
  const registrationHasCurrentTriggerStatus = !!registrationCurrentTriggerStatuses.length;
  const currentTriggerStatusNames = registrationCurrentTriggerStatuses.map(
    (s) => s.school_property.name,
  );

  const hasCurrentStatusRestriction = isFutureYearRegistration
    ? registrationHasCurrentTriggerStatus
    : registrationHasCurrentStatus;

  const canEditYear = !reEnrollmentDisabledStatuses.length && !hasCurrentStatusRestriction;

  const isInitialRegistration = !!currenRegistration?.initial;

  const isSaving = addEnrollment.isLoading || updateEnrollment.isLoading || isReEnrollmentUpdating;
  const isDeleting = deleteEnrollment.isLoading;

  const renderDeleteButton = () => {
    if (!id) return;

    let title;

    if (isPastYearRegistration) {
      title = formatMessage({ id: 'students-CannotDeleteRegistrationForPastYear' });
    } else if (!!triggerDisabledStatuses.length) {
      title = formatMessage({ id: 'students-CannotDeleteRegistrationWithInvoices' });
    } else if (isInitialRegistration) {
      title = formatMessage({ id: 'students-CannotDeleteInitialRegistration' });
    }

    const disabled =
      isSaving ||
      isDeleting ||
      isPastYearRegistration ||
      isInitialRegistration ||
      !!triggerDisabledStatuses.length;

    return (
      <Tooltip title={title} componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}>
        <Box>
          <Button
            startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
            variant="outlined"
            disabled={disabled}
            onClick={handleRemoveRegistration}
            data-test-id={MODAL.DELETE}
          >
            <FormattedMessage id="action-Delete" />
          </Button>
        </Box>
      </Tooltip>
    );
  };

  const renderYearEndIcon = useCallback(() => {
    if (canEditYear) return;

    return (
      <Tooltip
        componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
        title={
          hasCurrentStatusRestriction ? (
            <>
              <Typography mb={2}>
                {formatMessage(
                  {
                    id: isFutureYearRegistration
                      ? 'students-CannotChangeFutureSchoolYear-1'
                      : 'students-CannotChangeSchoolYear-1',
                  },
                  {
                    statuses: currentTriggerStatusNames.join(', '),
                    count: currentTriggerStatusNames.length,
                  },
                )}
              </Typography>
              <Typography>
                {formatMessage({
                  id: isFutureYearRegistration
                    ? 'students-CannotChangeFutureSchoolYear-2'
                    : 'students-CannotChangeSchoolYear-2',
                })}
              </Typography>
            </>
          ) : undefined
        }
      >
        <Icon sx={{ pointerEvents: 'auto', ':hover': { color: 'primary.main' } }}>
          <LockIcon />
        </Icon>
      </Tooltip>
    );
  }, [
    canEditYear,
    currentTriggerStatusNames,
    formatMessage,
    hasCurrentStatusRestriction,
    isFutureYearRegistration,
  ]);

  if (!hasPermission || data?.rollover_in_process) return <AccessDenied />;

  if (isFetchingInvoicingData || isFetchingProducts) return <Loading />;

  if (id && !currenRegistration) return null;

  return (
    <StudentRegistrationForm
      schoolId={schoolId}
      fetchRegistrations={fetchRegistrations}
      defaultValues={registrationFormData}
      disabledStatuses={disabledStatuses}
      onSubmit={handleSubmit}
      isDeleting={isDeleting}
      isSaving={isSaving}
      registrations={data?.enrollments}
      routePathname={`${pathname}/registration`}
      isLoading={
        isRegistrationFetching || isReEnrollmentDataLoading || !leavingStatus?.leaving_status_id
      }
      leavingStatusId={leavingStatus?.leaving_status_id || ''}
      registrationId={id}
      onManageAgeGroups={() => navigate('/settings/age_groups')}
      canEditAgeGroup={canEditAgeGroup}
      canEditYear={canEditYear}
      renderDeleteButton={renderDeleteButton}
      renderYearEndIcon={renderYearEndIcon}
      initial={isInitialRegistration}
      products={products}
    >
      <ModalHeader active title={getUserFullNameWithTitle(user)}>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
    </StudentRegistrationForm>
  );
};
