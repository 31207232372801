export enum SchoolType {
  Regular = 'regular',
  Additional = 'additional',
  Other = 'other',
}

export enum SchoolKind {
  Elementary = 'elementary',
  High = 'high',
  ElementaryAndHigh = 'elementary_and_high',
  Music = 'music_academy',
  Sports = 'sports_academy',
}

export enum RegularSchoolKind {
  Elementary = SchoolKind.Elementary,
  High = SchoolKind.High,
  ElementaryAndHigh = SchoolKind.ElementaryAndHigh,
}

export enum AdditionalSchoolKind {
  Music = SchoolKind.Music,
  Sports = SchoolKind.Sports,
}

export enum SchoolEducationSystem {
  US = 'US',
  UK = 'UK',
  AU = 'AU',
  BR = 'BR',
}

export enum SchoolPresetsLanguage {
  EN = 'en',
  PT = 'pt',
}

export enum SchoolUserRole {
  Admin = -1,
  Staff,
  Student,
  Parent,
  Application,
}

export enum SchoolPropertyType {
  Campus = 'campus',
  House = 'house',
  AgeGroup = 'age_group',
  Status = 'status',
  Department = 'department',
}

export enum SuggestedChangeDataSet {
  Name = 'name',
  Address = 'address',
  Nationality = 'nationality',
  Telephones = 'telephones',
  Email = 'email',
}

export const PROPERTIES_TEXT_IDS = {
  date: 'schoolProperty-Date',
  intersect_date: 'schoolProperty-Date',
  single_date: 'schoolProperty-Date',
  status: 'schoolProperty-Status',
  student_status: 'schoolProperty-Status',
  staff_status: 'schoolProperty-Status',
  department: 'schoolProperty-Department',
  student_department: 'schoolProperty-Department',
  staff_department: 'schoolProperty-Department',
  age_group: 'schoolProperty-AgeGroup',
  student_age_group: 'schoolProperty-AgeGroup',
  staff_age_group: 'schoolProperty-AgeGroup',
  house: 'schoolProperty-House',
  student_house: 'schoolProperty-House',
  staff_house: 'schoolProperty-House',
  campus: 'schoolProperty-Campus',
  nationality: 'peopleDetail-Nationality',
  gender: 'peopleDetail-Gender',
  only_tutor_groups: 'groups-OnlyTutorGroups',
  subject: 'groups-GroupSubject',
  group: 'groups-Group',
  tutor_group: 'groups-TutorGroup',
  staff: 'schoolProperty-Staff',
  student: 'schoolProperty-Student',
  creator: 'schoolProperty-From',
  staff_messages: 'schoolProperty-From',
  message_status: 'messages-Status',
  recipient_type: 'messages-Recipients',
  assessment_status: 'assessments-AssessmentStatus',
  event_status: 'events-EventStatus',
  event_type: 'events-EventType',
  invite_type: 'events-EventParticipation',
  sign_up_status: 'eventSignUps-SignUpStatus',
  sign_up_type: 'eventSignUps-SignUpType',
  report_status: 'reports-ReportStatus',
  report: 'schoolProperty-Report',
  conduct_type: 'conduct-ConductType',
  conduct_entry_ids: '',
  visibility: 'visibility',
  invite_status: 'schoolProperty-InviteStatus',
  empty_email_only: 'peopleDetail-NoEmail',
  month: 'messages-Month',
  sender: 'messages-Sender',
  read_percent: 'messages-ReadPercent',
  expired: 'schoolProperty-NoStatus',
  school_year: 'students-SchoolYear',
  record_type: '',
  recurrence_id: 'events-recurring',
  repetition_type: 'recurring-repetitionType',

  product_ids: 'products-Product',
  product_type_ids: 'products-productType',
  payer_ids: 'profile-Payers',
  day_past_due: 'payableFees-daysPastDue',
  frequency_types: 'frequencies-Frequency',
  fee_status: 'schoolProperty-Status',

  due: 'frequencies-DueDate',
  overdue: 'payableFees-status-overdue',
  discount: 'payableFees-Discrepancy-Type-invoice_item_discount_changed',
  show_by_present_absent: 'filter-ShowByPresentAbsent',
  leaving_reason: 'schoolProperty-ReasonsForLeaving',
};

export type PropertiesTextIds = keyof typeof PROPERTIES_TEXT_IDS;

export const SCHOOL_TYPE_LABELS = {
  [SchoolType.Regular]: 'school-create-regular-school',
  [SchoolType.Additional]: 'school-create-AdditionalEdu-school',
  [SchoolType.Other]: 'school-create-somethingElse',
};

export const SCHOOL_TYPE_OPTIONS = [
  {
    value: SchoolType.Regular,
    labelTextId: SCHOOL_TYPE_LABELS[SchoolType.Regular],
  },
  {
    value: SchoolType.Additional,
    labelTextId: SCHOOL_TYPE_LABELS[SchoolType.Additional],
  },
  {
    value: SchoolType.Other,
    labelTextId: SCHOOL_TYPE_LABELS[SchoolType.Other],
  },
];

export const REGULAR_KIND_LABELS = {
  [SchoolKind.Elementary]: {
    [SchoolEducationSystem.UK]: 'school-create-primary-school',
    [SchoolEducationSystem.US]: 'school-create-elementary-school',
    [SchoolEducationSystem.AU]: 'school-create-primary-school',
    [SchoolEducationSystem.BR]: 'school-create-primary-school',
  },
  [SchoolKind.High]: {
    [SchoolEducationSystem.UK]: 'school-create-secondary-school',
    [SchoolEducationSystem.US]: 'school-create-high-school',
    [SchoolEducationSystem.AU]: 'school-create-secondary-school',
    [SchoolEducationSystem.BR]: 'school-create-secondary-school',
  },
  [SchoolKind.ElementaryAndHigh]: {
    [SchoolEducationSystem.UK]: 'school-create-primary-secondary-school',
    [SchoolEducationSystem.US]: 'school-create-pk12-school',
    [SchoolEducationSystem.AU]: 'school-create-primary-secondary-school',
    [SchoolEducationSystem.BR]: 'school-create-primary-secondary-school',
  },
};

export const REGULAR_KIND_OPTIONS = [
  {
    value: SchoolKind.Elementary,
    labelTextId: {
      [SchoolEducationSystem.UK]:
        REGULAR_KIND_LABELS[SchoolKind.Elementary][SchoolEducationSystem.UK],
      [SchoolEducationSystem.US]:
        REGULAR_KIND_LABELS[SchoolKind.Elementary][SchoolEducationSystem.US],
      [SchoolEducationSystem.AU]:
        REGULAR_KIND_LABELS[SchoolKind.Elementary][SchoolEducationSystem.AU],
      [SchoolEducationSystem.BR]:
        REGULAR_KIND_LABELS[SchoolKind.Elementary][SchoolEducationSystem.BR],
    },
  },
  {
    value: SchoolKind.High,
    labelTextId: {
      [SchoolEducationSystem.UK]: REGULAR_KIND_LABELS[SchoolKind.High][SchoolEducationSystem.UK],
      [SchoolEducationSystem.US]: REGULAR_KIND_LABELS[SchoolKind.High][SchoolEducationSystem.US],
      [SchoolEducationSystem.AU]: REGULAR_KIND_LABELS[SchoolKind.High][SchoolEducationSystem.AU],
      [SchoolEducationSystem.BR]: REGULAR_KIND_LABELS[SchoolKind.High][SchoolEducationSystem.BR],
    },
  },
  {
    value: SchoolKind.ElementaryAndHigh,
    labelTextId: {
      [SchoolEducationSystem.UK]:
        REGULAR_KIND_LABELS[SchoolKind.ElementaryAndHigh][SchoolEducationSystem.UK],
      [SchoolEducationSystem.US]:
        REGULAR_KIND_LABELS[SchoolKind.ElementaryAndHigh][SchoolEducationSystem.US],
      [SchoolEducationSystem.AU]:
        REGULAR_KIND_LABELS[SchoolKind.ElementaryAndHigh][SchoolEducationSystem.AU],
      [SchoolEducationSystem.BR]:
        REGULAR_KIND_LABELS[SchoolKind.ElementaryAndHigh][SchoolEducationSystem.BR],
    },
  },
];

export const ADDITIONAL_KIND_LABELS = {
  [SchoolKind.Music]: 'school-create-type-music',
  [SchoolKind.Sports]: 'school-create-type-sport',
};

export const ADDITIONAL_KIND_OPTIONS = [
  {
    value: SchoolKind.Music,
    labelTextId: ADDITIONAL_KIND_LABELS[SchoolKind.Music],
  },
  {
    value: SchoolKind.Sports,
    labelTextId: ADDITIONAL_KIND_LABELS[SchoolKind.Sports],
  },
];
