import { Box, Stack, Typography } from '@mui/material';
import { RegistrationStatus, SHORT_FORMATTED_DATE_FORMAT_FNS, SyncUser } from '@schooly/api';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';

interface RegistrationLeavingNoticeInfoProps {
  status: RegistrationStatus;
  user: SyncUser;
}

export const RegistrationLeavingNoticeInfo: FC<RegistrationLeavingNoticeInfoProps> = ({
  status,
  user,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={(theme) => ({
        padding: 2,
        backgroundColor: theme.palette.common.orange5,
        borderRadius: 1,
        border: theme.mixins.borderValue(),
        borderColor: theme.palette.common.orange,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        cursor: 'default',
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <Typography
        variant="h3"
        color="common.orange"
        sx={(theme) => ({ color: `${theme.palette.common.orange} !important` })}
      >
        {getUserFullName(user)} {formatMessage({ id: 'profile-WillLeaveTheSchool' })}{' '}
        {format(new Date(status.applies_from), SHORT_FORMATTED_DATE_FORMAT_FNS)}
      </Typography>

      <Stack direction="row">
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: `${theme.palette.common.grey} !important`,
            mr: 0.5,
          })}
        >
          {formatMessage({ id: 'profile-SubmissionDate' })}
          {': '}
        </Typography>

        <Typography variant="body1" color="common.grey2">
          <Box display="inline-block">
            {format(new Date(status.created_at), SHORT_FORMATTED_DATE_FORMAT_FNS)}
          </Box>
        </Typography>
      </Stack>

      <Stack direction="row">
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: `${theme.palette.common.grey} !important`,
            mr: 0.5,
          })}
        >
          {formatMessage({ id: 'profile-SubmittedBy' })}
          {': '}
        </Typography>

        <Link
          to={getRouteModalPathname('adult', status.created_by)}
          onClick={(e) => e.stopPropagation()}
        >
          <Typography variant="body1" color="common.grey2">
            <Box display="inline-block" sx={{ ':hover': { textDecoration: 'underline' } }}>
              {getUserFullName(status.created_by)}
            </Box>
          </Typography>
        </Link>
      </Stack>

      <Stack direction="row">
        <Typography
          variant="body1"
          sx={(theme) => ({
            color: `${theme.palette.common.grey} !important`,
            mr: 0.5,
          })}
        >
          {formatMessage({ id: 'profile-LeavingReason' })}
          {': '}
        </Typography>

        <Typography variant="body1" color="common.grey2">
          <Box display="inline-block">{status.leaving_reason?.title}</Box>
        </Typography>
      </Stack>
    </Box>
  );
};
