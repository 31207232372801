import {
  ApiError,
  FilterValue,
  GET_ATTENDANCE_CODES_QUERY,
  GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY,
  NotificationAttendanceSettings,
  NotificationInternalEmails,
  NotificationSettings,
  RQUseQueryOptions,
  useGetNotificationAttendanceSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

export const useNotificationSettings = (options?: RQUseQueryOptions<NotificationSettings>) => {
  const { schoolId = '' } = useAuth();
  const queryClient = useQueryClient();
  const { showError } = useNotifications();

  const [selectedCodes, setSelectedCodes] = useState<FilterValue[]>([]);

  const { mutateAsync: updateNotificationSettings, isLoading: loadingUpdateNotificationSettings } =
    useUpdateNotificationSettingsMutation();

  const { data: notificationSettings, isLoading: loadingNotificationSettings } =
    useGetNotificationAttendanceSettingsQuery(
      { schoolId: schoolId ?? '' },
      {
        enabled: !!schoolId,
        refetchOnMount: 'always',
      },
    );

  const attendanceCodeIds = useMemo(
    () =>
      notificationSettings?.attendance.notifications.reduce<string[]>(
        (acc, { id, has_push_notifications }) => (has_push_notifications ? [...acc, id] : acc),
        [],
      ),
    [notificationSettings],
  );

  const attendanceEnabled = useMemo(
    () => notificationSettings?.attendance.enabled,
    [notificationSettings],
  );

  const changeAttendanceNotifications = useCallback(
    async ({ notifications, enabled }: NotificationAttendanceSettings) => {
      const { attendance } = await updateNotificationSettings(
        {
          schoolId,
          attendance: {
            notifications,
            enabled: Boolean(notifications.length) ? enabled : false,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([GET_NOTIFICATION_ATTENDANCE_SETTINGS_QUERY]);
            queryClient.invalidateQueries([GET_ATTENDANCE_CODES_QUERY]);
          },
          onError: (err) => {
            showError(err as ApiError);
            setSelectedCodes(attendanceCodeIds ?? []);
          },
        },
      );

      return attendance;
    },
    [schoolId, queryClient, showError, attendanceCodeIds, updateNotificationSettings],
  );

  const updateInternalEmailsNotifications = useCallback(
    async (internalEmails: NotificationInternalEmails) => {
      if (!schoolId) {
        return;
      }

      await updateNotificationSettings(
        {
          schoolId,
          internalEmails,
        },
        {
          onError: showError,
        },
      );
    },
    [schoolId, updateNotificationSettings, showError],
  );

  const onCodesSubmit = useCallback(
    async (enabled: boolean) => {
      if (selectedCodes === undefined || isEqual(attendanceCodeIds, selectedCodes)) {
        return;
      }

      const codes = selectedCodes ?? attendanceCodeIds;

      const data = codes?.map((id) => {
        return { id: id.toString() };
      });

      const response = await changeAttendanceNotifications({
        enabled,
        notifications: data ?? [],
      });

      if (response) {
        return response;
      }
    },
    [attendanceCodeIds, selectedCodes, changeAttendanceNotifications],
  );

  const onToggleSwitch = useCallback(
    async (enabled: boolean) => {
      const codes = selectedCodes ?? attendanceCodeIds;

      const data = codes?.map((id) => {
        return { id: id.toString() };
      });

      const response = await changeAttendanceNotifications({
        enabled,
        notifications: data,
      });

      if (response) {
        return response;
      }
    },
    [selectedCodes, attendanceCodeIds, changeAttendanceNotifications],
  );

  return {
    loadingNotificationSettings,
    loadingUpdateNotificationSettings,
    attendanceNotificationsEnabled: !!attendanceEnabled,
    selectedCodes,
    setSelectedCodes,
    onCodesSubmit,
    onToggleSwitch,
    updateInternalEmailsNotifications,
    attendanceCodeIds,
  };
};
