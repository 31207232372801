import { Stack, Typography } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import { FC, useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolCreateForm } from './useSchoolCreate';

interface SchoolCreateEmailsProps {
  form: UseFormReturn<SchoolCreateForm, any>;
}

export const SchoolCreateEmails: FC<SchoolCreateEmailsProps> = ({ form }) => {
  const { $t, formatMessage } = useIntl();

  const admissionsEmail = form.watch('notificationEmails.admissionsEmail');
  const invoicingEmail = form.watch('notificationEmails.invoicingEmail');

  useEffect(() => {
    form.trigger();
  }, [admissionsEmail, invoicingEmail, form]);

  return (
    <Stack height={'80vh'}>
      <Stack marginY="auto">
        <Stack gap={1}>
          <Typography variant="h1">
            {$t({ id: 'school-create-EmailsForNotificationsTitle' })}
          </Typography>

          <Typography variant="h3">
            {$t({ id: 'school-create-EmailsForNotificationsDescription' })}
          </Typography>
        </Stack>

        <Stack gap={2}>
          <ControlTextField
            control={form.control}
            name="notificationEmails.admissionsEmail"
            rules={{
              required: true,
              pattern: {
                value: VALID_EMAIL_REGEXP,
                message: formatMessage({
                  id: 'input-ErrorInvalidEmail',
                }),
              },
            }}
            label={$t({ id: 'school-sections-Notifications-Internal-AdmissionsEmail' })}
          />

          <ControlTextField
            control={form.control}
            name="notificationEmails.invoicingEmail"
            rules={{
              required: true,
              pattern: {
                value: VALID_EMAIL_REGEXP,
                message: formatMessage({
                  id: 'input-ErrorInvalidEmail',
                }),
              },
            }}
            label={$t({ id: 'school-sections-Notifications-Internal-InvoicingEmail' })}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
