import { Card, Icon, Stack, Typography } from '@mui/material';
import { AttendanceCode } from '@schooly/api';
import { Spin } from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAttendanceCodes } from '../../../../hooks/useAttendanceCodes';
import { useNotificationSettings } from '../../../../hooks/useNotificationSettings';
import { SchoolNotificationsAttendanceContent } from './SchoolNotificationsAttendanceContent';

export const SchoolNotificationsAttendance: FC = () => {
  const {
    attendanceNotificationsEnabled,
    selectedCodes,
    setSelectedCodes,
    onCodesSubmit,
    onToggleSwitch,
    attendanceCodeIds,
    loadingNotificationSettings,
    loadingUpdateNotificationSettings,
  } = useNotificationSettings();

  const [changesCompleted, setChangesCompleted] = useState<boolean>();

  const { attendanceCodes } = useAttendanceCodes();

  const { selectedAttendanceCodes, selectedAttendanceCodeIds } = useMemo(() => {
    const selectedIds = (selectedCodes ? selectedCodes : attendanceCodeIds) ?? [];
    return {
      selectedAttendanceCodeIds: selectedIds,
      selectedAttendanceCodes: attendanceCodes.reduce<Array<AttendanceCode>>(
        (acc, code) => (selectedIds.includes(code.id) ? [...acc, code] : acc),
        [],
      ),
    };
  }, [attendanceCodeIds, attendanceCodes, selectedCodes]);

  const onSubmit = useCallback(
    async (enabled: boolean) => {
      return await onCodesSubmit(enabled);
    },
    [onCodesSubmit],
  );

  const onSwitch = useCallback(
    async (enabled: boolean) => {
      return await onToggleSwitch(enabled);
    },
    [onToggleSwitch],
  );

  const handleSelectCode = useCallback(
    async (code: AttendanceCode) => {
      const isSelected = selectedAttendanceCodeIds.some((id) => id === code.id);

      setSelectedCodes(
        isSelected
          ? selectedAttendanceCodeIds.filter((id) => id !== code.id)
          : [...selectedAttendanceCodeIds, code.id],
      );
    },
    [selectedAttendanceCodeIds, setSelectedCodes],
  );

  return (
    <Card>
      <Stack sx={{ p: 2.5 }} direction="row">
        <Typography variant="h2">
          <FormattedMessage id="attendance-Title" />
        </Typography>

        {loadingNotificationSettings ? (
          <Stack justifyContent="center" alignItems="center" width="100%">
            <Icon fontSize="medium">
              <Spin />
            </Icon>
          </Stack>
        ) : (
          <SchoolNotificationsAttendanceContent
            onSubmit={onSubmit}
            onSwitch={onSwitch}
            attendanceCodes={attendanceCodes}
            selectedCodes={selectedAttendanceCodes}
            notificationsEnabled={attendanceNotificationsEnabled}
            onSelectCode={handleSelectCode}
            updating={loadingUpdateNotificationSettings}
            changesCompleted={changesCompleted}
            setChangesCompleted={setChangesCompleted}
          />
        )}
      </Stack>
    </Card>
  );
};
