import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { FilterKeys, WithAvatar, WithName } from '../';
import { Event } from './apiTypes/events';
import { Message } from './apiTypes/messages';
import { ApiError, PagedResponse } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { SignUp } from './apiTypes/signups';
import * as api from './requests';

const CONSENT_FORMS_URL = '/consent-form';

export type ConsentFormReferenceType = 'signup' | 'event' | 'message';

export const ConsentFormItemStatuses = ['agreed', 'declined', 'no_status'] as const;

export type ConsentFormItemStatus = typeof ConsentFormItemStatuses[number];

type EventReference = {
  type: 'event';
  data: Event;
};

type MessageReference = {
  type: 'message';
  data: Message;
};

type SignUpReference = {
  type: 'signup';
  data: SignUp;
};

export type ConsentFormReference = EventReference | MessageReference | SignUpReference;

export type ConsentFormNotPublished = {
  id: string;
  description: string;
  type: 'not_published';
  reference: ConsentFormReference;
};
export declare type Without<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export type ConsentFormPublished = {
  type: 'published';
  publish_date: string;
  statuses: {
    agreed: number;
    declined: number;
    no_status: number;
  };
} & Omit<ConsentFormNotPublished, 'type'>;

export type ConsentForm = ConsentFormNotPublished | ConsentFormPublished;
export type ConsentFormShort = DistributiveOmit<ConsentForm, 'reference'>;

export interface StudentForConsentForm extends WithName, WithAvatar {
  relation_id: string;
}

export type ConsentPerson = {
  relation_id: string;
  email?: string;
  telephone?: string;
} & WithName &
  WithAvatar;

export type ConsentFormItemShort = {
  id: string;
  signed_by: ConsentPerson | null;
  signed_for: StudentForConsentForm;
  sign_date: string | null;
  status: ConsentFormItemStatus;
};

export type ConsentFormItem = {
  reference: ConsentFormReference;
  consent_form_id: string;
  publish_date: string;
  creation_date: string;
} & ConsentFormItemShort;

export type ConsentFormWithVersions = {
  items: Array<ConsentFormItemShort>;
} & ConsentFormPublished;

export const getConsentForm = (id: string): Promise<ConsentForm> => {
  return api.get(`${CONSENT_FORMS_URL}/${id}`);
};

export const GET_CONSENT_FORM_QUERY = `${CONSENT_FORMS_URL}/GET_CONSENT_FORM_QUERY`;

export const useGetConsentFormQuery = (id: string, options?: RQUseQueryOptions<ConsentForm>) => {
  return useQuery<ConsentForm, ApiError>([GET_CONSENT_FORM_QUERY, id], () => getConsentForm(id), {
    ...options,
  });
};

type GetConsentFormItemsRequest = {
  id: string;
  statuses: ConsentFormItemStatus[];
  page_number?: number;
  page_size?: number;
  search_query?: string;
};

export const getConsentFormItems = ({
  id,
  statuses,
  ...params
}: GetConsentFormItemsRequest): Promise<PagedResponse<ConsentFormItemShort>> => {
  return api.get(`${CONSENT_FORMS_URL}/${id}/items`, {
    params: {
      ...params,
      statuses: statuses.join(','),
    },
  });
};

export const GET_CONSENT_FORM_ITEMS_QUERY = `${CONSENT_FORMS_URL}/GET_CONSENT_FORM_ITEMS_QUERY`;

export const useGetConsentFormItemsQuery = (
  initialParams: GetConsentFormItemsRequest,
  options?: RQUseInfiniteQueryOptions<PagedResponse<ConsentFormItemShort>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<ConsentFormItemShort>, ApiError>(
    [GET_CONSENT_FORM_ITEMS_QUERY, params],
    ({ pageParam }) =>
      getConsentFormItems({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

type GetConsentFormItemsForRelationRequest = {
  relation_id: string;
  year_id: string;
  search_query?: string;
  statuses?: ConsentFormItemStatus[];
  page_number?: number;
  page_size?: number;
};

export const getConsentFormItemsForRelation = ({
  relation_id,
  statuses,
  ...params
}: GetConsentFormItemsForRelationRequest): Promise<PagedResponse<ConsentFormItem>> => {
  return api.get(`${CONSENT_FORMS_URL}/for-relation/${relation_id}`, {
    params: {
      ...params,
      statuses: statuses?.join(','),
    },
  });
};

export type GetConsentFormQueryFilters = {
  [FilterKeys.Status]?: ConsentFormItemStatus[];
};

export const GET_CONSENT_FORM_ITEMS_FOR_RELATION_QUERY = `${CONSENT_FORMS_URL}/GET_CONSENT_FORM_ITEMS_FOR_RELATION_QUERY`;

export const useGetConsentFormItemsForRelationQuery = (
  initialParams: GetConsentFormItemsForRelationRequest,
  options?: RQUseInfiniteQueryOptions<PagedResponse<ConsentFormItem>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<ConsentFormItem>, ApiError>(
    [GET_CONSENT_FORM_ITEMS_FOR_RELATION_QUERY, params],
    ({ pageParam }) =>
      getConsentFormItemsForRelation({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

type GetConsentFormsHistoryRequest = {
  relation_id: string;
  year_id: string;
  search_query?: string;
  statuses?: ConsentFormItemStatus[];
  page_number?: number;
  page_size?: number;
};

export const getConsentFormWithHistory = ({
  relation_id,
  ...params
}: GetConsentFormsHistoryRequest): Promise<PagedResponse<ConsentFormWithVersions>> => {
  return api.get(`${CONSENT_FORMS_URL}/for-relation/${relation_id}/history`, { params });
};

export const GET_CONSENT_FORMS_HISTORY = `${CONSENT_FORMS_URL}/GET_CONSENT_FORMS_HISTORY`;

export const useGetConsentFormsHistoryQuery = (
  initialParams: GetConsentFormsHistoryRequest,
  options?: RQUseInfiniteQueryOptions<PagedResponse<ConsentFormWithVersions>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<ConsentFormWithVersions>, ApiError>(
    [GET_CONSENT_FORMS_HISTORY, params],
    ({ pageParam }) =>
      getConsentFormWithHistory({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type CreateConsentFormRequest = {
  reference_type: ConsentFormReferenceType;
  reference_id: string;
  description: string;
};

export const CREATE_CONSENT_FORM_MUTATION = `${CONSENT_FORMS_URL}CREATE_CONSENT_FORM_MUTATION`;

export function createConsentForm(params: CreateConsentFormRequest): Promise<ConsentForm> {
  return api.post(`${CONSENT_FORMS_URL}/create`, { ...params });
}

export const useCreateConsentFormMutation = (
  options?: RQUseMutationOptions<ConsentForm, CreateConsentFormRequest>,
): RQUseMutationResult<ConsentForm, CreateConsentFormRequest> => {
  return useMutation(
    [CREATE_CONSENT_FORM_MUTATION],
    (params: CreateConsentFormRequest) => createConsentForm(params),
    {
      ...options,
    },
  );
};

type UpdateConsentFormRequest = {
  id: string;
  reference_type: ConsentFormReferenceType;
  reference_id: string;
  description: string;
};

export const UPDATE_CONSENT_FORM_MUTATION = `${CONSENT_FORMS_URL}UPDATE_CONSENT_FORM_MUTATION`;

export function updateConsentForm({
  id,
  ...params
}: UpdateConsentFormRequest): Promise<ConsentForm> {
  return api.patch(`${CONSENT_FORMS_URL}/${id}`, { ...params });
}

export const useUpdateConsentFormMutation = (
  options?: RQUseMutationOptions<ConsentForm, UpdateConsentFormRequest>,
): RQUseMutationResult<ConsentForm, UpdateConsentFormRequest> => {
  return useMutation(
    [UPDATE_CONSENT_FORM_MUTATION],
    (params: UpdateConsentFormRequest) => updateConsentForm(params),
    {
      ...options,
    },
  );
};

export const REMOVE_CONSENT_FORM_MUTATION = `${CONSENT_FORMS_URL}REMOVE_CONSENT_FORM_MUTATION`;

type RemoveConsentFormResponse = { success: string };

export function removeConsentForm(id: string): Promise<RemoveConsentFormResponse> {
  return api.remove(`${CONSENT_FORMS_URL}/${id}`);
}

export const useRemoveConsentFormMutation = (
  options?: RQUseMutationOptions<RemoveConsentFormResponse, string>,
): RQUseMutationResult<RemoveConsentFormResponse, string> => {
  return useMutation([REMOVE_CONSENT_FORM_MUTATION], (id: string) => removeConsentForm(id), {
    ...options,
  });
};

type DownloadConsentFormItems = {
  statuses: ConsentFormItemStatus[];
  id: string;
};

export const downloadConsentFormItems = ({
  id,
  statuses,
}: DownloadConsentFormItems): Promise<ArrayBuffer> => {
  return api.get(`/export/consent-form/${id}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    params: {
      statuses: statuses.join(','),
    },
  });
};

export const useDownloadConsentFormItemsMutation = (
  options?: RQUseMutationOptions<ArrayBuffer, DownloadConsentFormItems>,
): RQUseMutationResult<ArrayBuffer, DownloadConsentFormItems> => {
  return useMutation((params) => downloadConsentFormItems(params), {
    ...options,
  });
};
