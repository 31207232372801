import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useUpdateNotificationSettingsMutation } from '@schooly/api';
import { ControlTextField } from '@schooly/components/form-text-field';
import { VALID_EMAIL_REGEXP } from '@schooly/constants';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CrossIcon,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spin,
} from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useRouter } from 'apps/web/src/context/router/useRouter';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useSchool } from 'apps/web/src/hooks/useSchool';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { SchoolCreateEmailsForm } from '../../../SchoolCreate/useSchoolCreate';
import { SchoolSettingsLayout } from '../../../SchoolSettingsLayout';

export const SchoolTuneNotificationsModal = () => {
  const { goBack } = useRouter();
  const navigate = useNavigate();
  const { currentSchool } = useSchool();
  const { $t, formatMessage } = useIntl();

  const { mutateAsync: updateNotificationSettings, isLoading: loadingUpdateNotificationSettings } =
    useUpdateNotificationSettingsMutation();

  const form = useForm<SchoolCreateEmailsForm>({
    defaultValues: {
      admissionsEmail: '',
      invoicingEmail: '',
    },
    mode: 'onChange',
  });

  const onSubmit = useCallback<SubmitHandler<SchoolCreateEmailsForm>>(
    async (data) => {
      const res = await updateNotificationSettings({
        schoolId: currentSchool?.id || '',
        internalEmails: {
          admissionsEmail: data.admissionsEmail,
          invoicingEmail: data.invoicingEmail,
        },
      });

      if (res.internal_emails) navigate('/');
    },
    [navigate, updateNotificationSettings, currentSchool?.id],
  );

  return (
    <SchoolSettingsLayout>
      <ModalConfirm open maxWidth="md" onClose={goBack}>
        <ModalHeader title={currentSchool?.name}>
          <IconButton onClick={goBack}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <ModalContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Stack gap={1}>
                <Typography variant="h1">
                  {$t({ id: 'school-create-EmailsForNotificationsTitle' })}
                </Typography>

                <Typography variant="h3">
                  {$t({ id: 'school-create-EmailsForNotificationsDescription' })}
                </Typography>
              </Stack>

              <Stack gap={2}>
                <ControlTextField
                  control={form.control}
                  name="admissionsEmail"
                  rules={{
                    required: true,
                    pattern: {
                      value: VALID_EMAIL_REGEXP,
                      message: formatMessage({
                        id: 'input-ErrorInvalidEmail',
                      }),
                    },
                  }}
                  label={$t({ id: 'school-sections-Notifications-Internal-AdmissionsEmail' })}
                />

                <ControlTextField
                  control={form.control}
                  name="invoicingEmail"
                  rules={{
                    required: true,
                    pattern: {
                      value: VALID_EMAIL_REGEXP,
                      message: formatMessage({
                        id: 'input-ErrorInvalidEmail',
                      }),
                    },
                  }}
                  label={$t({ id: 'school-sections-Notifications-Internal-InvoicingEmail' })}
                />
              </Stack>
            </form>
          </FormProvider>
        </ModalContent>

        <ModalFooter active sx={{ justifyContent: 'space-between' }}>
          <Button variant="outlined" startIcon={<ArrowLeftIcon />} onClick={goBack}>
            <FormattedMessage id="action-Back" />
          </Button>

          <Button
            type="submit"
            disabled={!form.formState.isValid}
            data-convert
            endIcon={loadingUpdateNotificationSettings ? <Spin /> : <ArrowRightIcon />}
          >
            <FormattedMessage id="action-LetsStart" />
          </Button>
        </ModalFooter>
      </ModalConfirm>
    </SchoolSettingsLayout>
  );
};
