import { Box, FormControlLabel, Icon, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { SchoolReEnrollmentResponse, UpdateSchoolReEnrollmentRequestParams } from '@schooly/api';
import { isReEnrollmentPendingStatus } from '@schooly/components/annual-roll-over';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { EyeIcon, Spin } from '@schooly/style';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReEnrollmentInfo } from './ReEnrollmentInfo';

type SchoolReEnrollmentForm = {
  enabled: boolean;
  status_id: string;
};

export type ViewStudentProps = {
  yearId: string;
  statusId: string;
};

type SchoolReEnrollmentContentProps = {
  schoolId: string;
  data: SchoolReEnrollmentResponse;
  onSubmit: (v: UpdateSchoolReEnrollmentRequestParams) => void;
  isUpdating: boolean;
  onViewStudents: (d: ViewStudentProps) => void;
};

const defaultState = {
  enabled: false,
  status_id: undefined,
};

export const SchoolReEnrollmentContent: FC<SchoolReEnrollmentContentProps> = ({
  data,
  schoolId,
  onSubmit,
  isUpdating,
  onViewStudents,
}) => {
  const form = useForm<SchoolReEnrollmentForm>({
    defaultValues: {
      enabled: data.enabled,
      status_id: data.status_ids?.[0] ?? '',
    },
    mode: 'onChange',
  });
  const { $t } = useIntl();

  const enabled = form.watch('enabled');
  const statusId = form.watch('status_id');
  const [rolloverYearId] = data.rollover_year_ids;
  const pendingRegistrationExists = Boolean(enabled && rolloverYearId);

  const { activePropertiesMap } = useSchoolProperties({
    schoolId: schoolId ?? '',
    userType: SchoolUserRole.Student,
    showReEnrollmentProperties: true,
  });

  const currentStatus = useMemo(
    () => activePropertiesMap.status.find((s) => s.id === statusId),
    [activePropertiesMap.status, statusId],
  );

  useEffect(() => {
    const subscription = form.watch(({ enabled, status_id }, { name }) => {
      if (!name) return;

      if (name === 'enabled' && !enabled) {
        form.reset(defaultState);
      }

      onSubmit({ schoolId, enabled: Boolean(enabled), status_ids: [] });
    });

    return () => subscription.unsubscribe();
  }, [data.enabled, form, onSubmit, schoolId]);

  const onViewStudentsClick = useCallback(() => {
    if (!rolloverYearId) return;
    const statusId = activePropertiesMap.status.find(isReEnrollmentPendingStatus)?.id;
    if (!statusId) return;
    onViewStudents({ yearId: rolloverYearId, statusId });
  }, [activePropertiesMap.status, onViewStudents, rolloverYearId]);

  return (
    <Stack px={2.5}>
      <Stack flexGrow={1} alignItems="center" gap={2}>
        <FormProvider {...form}>
          <form>
            <Box>
              <Stack
                justifyContent="center"
                gap={1.5}
                sx={(theme) => ({
                  m: 0,
                  '.Mui-checked:hover': {
                    backgroundColor: 'transparent !important',
                  },
                  '& .Mui-disabled': {
                    '& .PrivateSwitchBase-input': {
                      pointerEvents: 'all',
                    },
                    ' .MuiSwitch-track, .Mui-checked+.MuiSwitch-track': {
                      opacity: 1,
                      borderColor: `${theme.palette.common.main5} !important`,
                    },
                    '.MuiSwitch-thumb': {
                      backgroundColor: theme.palette.common.main5,
                    },
                  },
                })}
              >
                <FormControlLabel
                  disabled={isUpdating || pendingRegistrationExists}
                  control={
                    <Stack width={40}>
                      {isUpdating ? (
                        <Icon
                          fontSize="medium"
                          sx={{
                            ml: 0.75,
                            color: 'primary.main',
                          }}
                        >
                          <Spin />
                        </Icon>
                      ) : (
                        <Tooltip
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: (theme) => ({
                                padding: theme.spacing(1.25),
                              }),
                            },
                          }}
                          title={
                            pendingRegistrationExists ? (
                              <Stack
                                gap={1.25}
                                sx={{
                                  cursor: 'pointer',
                                }}
                              >
                                <Typography>
                                  <FormattedMessage id="school-annualRollover-DisableTitle" />
                                </Typography>
                                <Stack
                                  direction="row"
                                  gap={1}
                                  onClick={onViewStudentsClick}
                                  alignItems="center"
                                >
                                  <Icon>
                                    <EyeIcon />
                                  </Icon>
                                  <Typography>
                                    <FormattedMessage id="school-annualRollover-ViewStudents" />
                                  </Typography>
                                </Stack>
                              </Stack>
                            ) : (
                              ''
                            )
                          }
                        >
                          <Switch
                            checked={enabled}
                            disabled={isUpdating || pendingRegistrationExists}
                            onChange={(e) => {
                              form.setValue('enabled', e.target.checked);
                            }}
                            sx={(theme) => ({
                              '&& .MuiSwitch-track': {
                                pointerEvents: 'none',
                                opacity: 1,
                                borderColor: `${theme.palette.common.main4} !important`,
                              },
                            })}
                          />
                        </Tooltip>
                      )}
                    </Stack>
                  }
                  label={
                    <Typography variant="h3" color={enabled ? undefined : 'common.grey'}>
                      <FormattedMessage id="school-annualRollover-ReEnrollmentConfirm" />
                    </Typography>
                  }
                />
              </Stack>
            </Box>
          </form>
        </FormProvider>
      </Stack>

      {enabled && (
        <ReEnrollmentInfo
          statusName={
            currentStatus
              ? `"${currentStatus.name}"`
              : `${$t({ id: 'school-tabs-Statuses-Status-Former' }).toLowerCase()}`
          }
        />
      )}
    </Stack>
  );
};
