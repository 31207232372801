import { LeavingReason } from '@schooly/api';
import { GridContainer, GridRowItem, GridRowName, GridRowStyled } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LongStringWithTooltip } from 'apps/web/src/components/uikit-components/LongStringWithTooltip/LongStringWithTooltip';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';

type SchoolLeavingReasonsForm = {
  reasons: LeavingReason[];
};

type SchoolLeavingReasonsContentProps = {
  reasons: LeavingReason[];
};

export const SchoolLeavingReasonsContent: FC<SchoolLeavingReasonsContentProps> = ({ reasons }) => {
  const form = useForm<SchoolLeavingReasonsForm>({
    defaultValues: {
      reasons: reasons,
    },
  });

  const formReasons = form.watch('reasons');

  return (
    <FormProvider {...form}>
      <GridContainer>
        {formReasons?.map((reason) => {
          return (
            <GridRowStyled key={reason.id}>
              <GridRowItem>
                <GridRowName sx={{ flex: '1 1 100%' }}>
                  <LongStringWithTooltip>{reason.title}</LongStringWithTooltip>
                </GridRowName>
              </GridRowItem>
            </GridRowStyled>
          );
        })}
      </GridContainer>
    </FormProvider>
  );
};
