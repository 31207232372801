import * as amplitude from '@amplitude/analytics-browser';
import { useAuth } from '@schooly/components/authentication';

export const useAmplitude = () => {
  const { permissions } = useAuth();

  //   School staff member presses "rollover & leaving" module
  const trackRolloverAndLeaveSectionOpened = () => {
    amplitude.track('RL_open', {
      user_role: permissions,
    });
  };

  //   School staff creates a new leaving reason
  const trackCreateLeavingReason = () => {
    amplitude.track('RL_add_reason', {
      user_role: permissions,
    });
  };

  //   School staff archives a new leaving reason
  const trackArchiveLeavingReason = () => {
    amplitude.track('RL_archive_reason', {
      user_role: permissions,
    });
  };

  return {
    trackRolloverAndLeaveSectionOpened,
    trackCreateLeavingReason,
    trackArchiveLeavingReason,
  };
};
