import {
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { AgeGroup, LeavingReason, SchoolProperty } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { ArchiveIcon, CrossIcon } from '@schooly/style';
import { theme } from '@schooly/style';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { AdornmentLabel } from '../SelectCommonComponents';
import { getPropertyTypeName, getSourcePropertyName } from '../utils';

export type PropertyTypeSelectProps = {
  hideIfNoOptions?: boolean;
  propertyType: SchoolPropertyType;
  userRole: SchoolUserRole;
  schoolId: string;
  hasError?: boolean;
  errorMessage?: string;
  adornmentLabel?: 'optional' | 'required';
  canClear?: boolean;
  onClear?: () => void;
  renderEndIcon?: () => JSX.Element;
  renderEmptyStub?: () => JSX.Element;
  optionalLabel?: ReactNode;
  showReEnrollmentProperties?: boolean;
} & SelectProps;

export const PropertyTypeSelect = ({
  hideIfNoOptions,
  schoolId,
  userRole,
  propertyType,
  errorMessage,
  hasError,
  adornmentLabel,
  onChange,
  disabled,
  canClear,
  onClear,
  renderEndIcon,
  renderEmptyStub,
  optionalLabel,
  showReEnrollmentProperties,
  ...props
}: PropertyTypeSelectProps) => {
  const { getConfirmation } = useConfirmationDialog();
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { $t } = useIntl();

  const { activePropertiesMap, isLoading, propertiesMap } = useSchoolProperties(
    { schoolId, userType: userRole },
    { refetchOnMount: 'always' },
  );

  const propertiesOfType: Array<SchoolProperty | AgeGroup | LeavingReason> =
    propertiesMap[propertyType];
  const activePropertiesOfType: Array<SchoolProperty | AgeGroup | LeavingReason> =
    activePropertiesMap[propertyType];

  const options = useMemo(
    () =>
      activePropertiesOfType.filter((o) => {
        const source = 'source' in o && o.source;
        if (!showReEnrollmentProperties && source && source.type === 're_enrollment') return false;
        return {
          ...o,
          name: source ? $t({ id: getSourcePropertyName(o) }) : getPropertyTypeName(o),
        };
      }) ?? [],
    [activePropertiesOfType, showReEnrollmentProperties, $t],
  );

  const handleClear = useCallback(async () => {
    const property = propertiesOfType.find((c) => c.id === props.value);
    const isConfirmed = await getConfirmation({
      textId: `deselect-property-archived-${propertyType}`,
      textValues: { name: getPropertyTypeName(property) },
    });
    if (isConfirmed && onClear) {
      onClear();
    }
  }, [getConfirmation, onClear, propertiesOfType, propertyType, props.value]);

  const renderClearIcon = useCallback(() => {
    return (
      <IconButton inverse onClick={handleClear}>
        <CrossIcon />
      </IconButton>
    );
  }, [handleClear]);

  const endIcon = useMemo(() => {
    if (!isOpen && canClear && onClear) {
      return renderClearIcon;
    }
    if (renderEndIcon) {
      return renderEndIcon;
    }
  }, [canClear, isOpen, onClear, renderClearIcon, renderEndIcon]);

  if (hideIfNoOptions && !isLoading && !propertiesMap[propertyType].length) {
    return null;
  }

  return (
    <FormControl
      sx={(theme) => ({
        '& .MuiOutlinedInput-root': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: hasError ? `${theme.palette.error.main} !important` : undefined,
            outline: '0px solid transparent',
            transition: 'all .2s',
          },

          '&:hover:not(.Mui-disabled)': {
            '& .MuiOutlinedInput-notchedOutline': {
              outline: `3px solid ${theme.palette.background.default}`,
            },
          },
          '&.Mui-disabled': {
            color: 'text.secondary',
            backgroundColor: theme.palette.background.default,
          },

          ' .MuiIcon-root, .MuiIconButton-root': {
            marginRight: 1.25,
          },
        },
        '&.MuiPaper-root MuiMenu-paper MuiPaper-elevation': {
          width: inputRef.current?.offsetWidth,
        },
      })}
      error={hasError}
    >
      <Select
        ref={inputRef}
        MenuProps={{
          ...theme.components?.MuiSelect?.defaultProps?.MenuProps,
          sx: {
            ...theme.components?.MuiSelect?.defaultProps?.MenuProps?.sx,
            '& .MuiMenu-paper': { maxWidth: inputRef.current?.offsetWidth },
          },
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        IconComponent={endIcon}
        required={undefined}
        renderValue={(v) => {
          const property = propertiesOfType.find((c) => c.id === v);

          return (
            <Stack direction="row" alignItems="center" gap={1}>
              {property?.archived ? (
                <Stack direction="row" alignItems="center">
                  <ArchiveIcon />
                  <span>{getPropertyTypeName(property)}</span>
                </Stack>
              ) : (
                <span>{getPropertyTypeName(property)}</span>
              )}
              {optionalLabel}
            </Stack>
          );
        }}
        onChange={async (event, child) => {
          const property = propertiesOfType.find((c) => c.id === props.value);

          if (property?.archived) {
            const isConfirmed = await getConfirmation({
              textId: `deselect-property-archived-${propertyType}`,
              textValues: { name: getPropertyTypeName(property) },
            });

            if (isConfirmed) {
              onChange?.(event, child);
            }
          } else {
            onChange?.(event, child);
          }
        }}
        disabled={disabled}
        {...props}
        value={props.value ? props.value : undefined}
      >
        {!options.length && renderEmptyStub?.()}
        {options.map((o) => (
          <MenuItem key={o.id} value={o.id}>
            {getPropertyTypeName(o)}
          </MenuItem>
        ))}
      </Select>
      {!props.value && !disabled && <AdornmentLabel label={adornmentLabel} />}
      <FormHelperText>
        <Typography variant="body2">{errorMessage}</Typography>
      </FormHelperText>
    </FormControl>
  );
};
